export type Environment = {
  version?: string;
  hmr: boolean;
  production: boolean;
  debug: {
    router: boolean;
  };
  apiUrl: string;
  googleClientId: string;
};
