import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'controllingCaseStatus',
})
export class ControllingCaseStatusPipe implements PipeTransform {
  transform(controllingCase: string): string {
    return {
      'on parole': 'On Parole',
      'out on probation': 'Out on probation',
      'convicted of arson': 'Convicted of Arson',
      'registered sex offender': 'Registered Sex Offender',
      'convicted of violent crime': 'Convicted of Violent Crime',
    }[controllingCase];
  }
}
