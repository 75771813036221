import { Pipe, PipeTransform } from '@angular/core';
import { capitalizeWord } from '@utils';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
  transform(firstName: string, lastName: string): any {
    return `${capitalizeWord(firstName)} ${capitalizeWord(lastName)}`;
  }
}
