import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clean',
})
export class CleanStatusPipe implements PipeTransform {
  transform(status: string): string {
    return {
      yes: 'Yes',
      no: 'No',
      never: 'Never used drugs',
    }[status];
  }
}