import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';

import { Applicant, ApplicantSupport } from '@types';
import { StorageList } from '@/app/@types/Storage';
import { Question, QuestionWithAnswer, UpdateAnswer } from '@/app/@types/question';

@Injectable()
export class ApplicantService {
  public id: string;
  public applicant: Applicant;
  public onQuestionsChanged: BehaviorSubject<any>;
  public onApplicantChanged: BehaviorSubject<any>;
  public onQuestionAnswersChanged: BehaviorSubject<any>;
  public onApplicantFilesChanged: BehaviorSubject<any>;
  public onApplicantSupportChanged: BehaviorSubject<any>;
  public onActivityChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onQuestionsChanged = new BehaviorSubject([]);
    this.onApplicantChanged = new BehaviorSubject({});
    this.onQuestionAnswersChanged = new BehaviorSubject([]);
    this.onApplicantSupportChanged = new BehaviorSubject({});
    this.onApplicantFilesChanged = new BehaviorSubject([]);
    this.onActivityChanged = new BehaviorSubject([]);
  }

  /**
   * Get API Data
   */
  getApplicant(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<Applicant>(`/api/applicant/${id}`).subscribe((response: any) => {
        this.applicant = response;
        this.onApplicantChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  getQuestions(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<Question[]>(`/api/question`).subscribe((response: any) => {
        this.onQuestionsChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  getQuestionAnswers(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<QuestionWithAnswer[]>(`/api/question-answer/${id}`).subscribe((response: any) => {
        this.onQuestionAnswersChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  getApplicantSupport(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<Applicant>(`/api/applicant-support/${id}`).subscribe((response: any) => {
        this.onApplicantSupportChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  listAllFiles(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<StorageList>(`/api/storage/files/${id}`).subscribe((response: any) => {
        this.onApplicantFilesChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  uploadFile(id: string, file: File, fileNote: string): Promise<any[]> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('note', fileNote);
    return new Promise((resolve, reject) => {
      this._httpClient.post<StorageList>(`/api/storage/files/${id}`, formData).subscribe((response: any) => {
        this.getApplicant(id).then();
        resolve(response);
      }, reject);
    });
  }

  downloadFile(applicantId: string, fileName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`/api/storage/${fileName}`, { responseType: 'blob', observe: 'response' })
        .subscribe((response: any) => {
          saveAs(response.body, fileName.split(applicantId + '/')[1]);
          resolve(response.body);
        }, reject);
    });
  }

  deleteFile(applicantId: string, fileName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`/api/storage/${fileName}`).subscribe((response: any) => {
        this.getApplicant(applicantId).then();
        resolve(response);
      }, reject);
    });
  }

  answerQuestions(applicantId: string, questionsWithAnswers: UpdateAnswer[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<QuestionWithAnswer[]>(`/api/question-answer/all/${applicantId}`, questionsWithAnswers)
        .subscribe((response: any) => {
          this.onQuestionAnswersChanged.next(response);
          resolve(response.body);
        }, reject);
    });
  }

  getApplicantActivity(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<Applicant>(`/api/question-answer/${id}`).subscribe((response: any) => {
        const answers = response
          .map((answer) => ({
            answer: answer.answer,
            category: answer.category,
            updatedAt: answer.updatedAt,
            order: answer.question.order,
            question: answer.question.question,
          }))
          .reduce((prev, current) => {
            if (prev[current.category]) {
              return {
                ...prev,
                [current.category]: [...prev[current.category], current],
              };
            }
            return {
              ...prev,
              [current.category]: [current],
            };
          }, {});
        this.onActivityChanged.next(answers);
        resolve(answers);
      }, reject);
    });
  }

  updateApplicant(applicant: Applicant): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.put<Applicant>(`/api/applicant/${applicant.id}`, applicant).subscribe((response: any) => {
        this.onApplicantChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  updateApplicantSupport(applicantSupport: ApplicantSupport): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.put<ApplicantSupport>(`/api/applicant-support/`, applicantSupport).subscribe((response: any) => {
        this.onApplicantSupportChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  createApplicantSupport(id: string, applicantSupport: ApplicantSupport): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<ApplicantSupport>(`/api/applicant-support/${id}`, applicantSupport)
        .subscribe((response: any) => {
          this.onApplicantSupportChanged.next(response);
          resolve(response);
        }, reject);
    });
  }

  saveApplicant(applicant: Applicant) {
    return new Promise((resolve, reject) => {
      this._httpClient.post<Applicant>(`/api/applicant/`, { ...applicant }).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  findApplicant(id: string) {
    return this._httpClient.get<Applicant>(`/api/applicant/${id}`).pipe(
      map((response) => {
        if (response) {
        }
        return response;
      }),
    );
  }

  deleteApplicant(id?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .delete<Applicant>(`/api/applicant/${id ?? this.applicant.id}`, { observe: 'response' })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getDraftApplicant(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<Applicant>(`/api/draft-applicant/${id}`).subscribe((response: any) => {
        this.applicant = response;
        this.onApplicantChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  updateDraftApplicant(applicant: Applicant): Promise<Applicant> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<Applicant>(`/api/draft-applicant/final/${applicant.id}`, applicant).subscribe((response: any) => {
        this.applicant = response;
        this.onApplicantChanged.next(response);
        resolve(response);
      }, reject);
    });
  }


  deleteDraftApplicant(id: string): Promise<HttpResponse<void>> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete<void>(`/api/draft-applicant/${id}`, { observe: 'response' }).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  exportApplicants(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`/api/reports/applicants`, { responseType: 'blob' })
        .subscribe((response: Blob) => {
          resolve(response);
        }, reject);
    });
  }

  exportClients(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`/api/reports/clients`, { responseType: 'blob' })
        .subscribe((response: Blob) => {
          resolve(response);
        }, reject);
    });
  }

}
