import { ApplicationStatus, Ethnicity, Gender } from './common';
import { Location } from './locations';

export interface Applicant {
  id?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  mobile: string;
  email: string;
  gender: Gender;
  avatar?: string;
  status: ApplicationStatus;
  address: string;
  city: string;
  state: string;
  country: string;
  createdAt: Date;
  updatedAt: Date;
  submittedAt: Date;
  type: ApplicantType;
  location?: Location;
  locationId?: string;
  notes?: Array<{ createdAt: Date; note: string }>;
  files?: Array<{ createdAt: Date; file: string }>;
}

export enum Housing {
  HOUSED = 'housed',
  NOT_HOUSED = 'not_housed',
}

export const housedTypes = [
  {
    value: Housing.HOUSED,
    label: 'Housed',
  },
  {
    value: Housing.HOUSED,
    label: 'Currently Not Housed',
  },
];

export interface ApplicantSupport {
  id?: string;
  emergencyContactName?: string;
  relationshipToEmergencyContactName?: string;
  emergencyContactNamePhone?: string;
  transporation?: string;
  housing?: Housing;
  partnerFullName?: string;
  relationship?: string;
  previousRehab?: string;
  rehabLocation?: string;
  groupMeetings?: string;
  createdAt?: Date;
  updatedAt?: Date;
  applicant?: Applicant;
  ethnicity?: Ethnicity;
}

export enum ApplicantType {
  CLIENT = 'client',
  APPLICANT = 'applicant',
}

export enum ControllingCase {
  ON_PAROLE = 'on parole',
  OUT_ON_PROBATION = 'out on probation',
  CONVICTED_OF_ARSON = 'convicted of arson',
  REGISTERED_SEX_OFFENDER = 'registered sex offender',
  CONVICTED_OF_VIOLENT_CRIME = 'convicted of violent crime',
}

export enum Drug {
  OPIOIDS = 'opioids',
  METAMPHATAMINE = 'methamphetamine',
  COCAINE = 'cocaine',
  FENTANYL = 'fentanyl',
}