import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';
import { NamePipe } from '@core/pipes/name.pipe';
import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { ApplicationStatusPipe } from './application-status.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';
import { DifferenceInDays } from './difference-in-days.pipe';
import { EthnicityPipe } from './ethnicity.pipe';
import { FormatPhoneNumberPipe } from './format-phone-number';
import { InitialsPipe } from './initials.pipe';
import { ProgramStatusPipe } from './program-status.pipe';
import { ControllingCaseStatusPipe } from './controlling-case.pipe';
import { drugStatusPipe } from './drug.pipe';
import { CleanStatusPipe } from './clean.pipe';
import { SoberStatusPipe } from './sober.pipe';

@NgModule({
  declarations: [
    NamePipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    ArrayToStringPipe,
    InitialsPipe,
    DifferenceInDays,
    FormatPhoneNumberPipe,
    ApplicationStatusPipe,
    ProgramStatusPipe,
    EthnicityPipe,
    drugStatusPipe,
    SoberStatusPipe,
    CleanStatusPipe,
    ControllingCaseStatusPipe,
  ],
  exports: [
    NamePipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    ArrayToStringPipe,
    InitialsPipe,
    DifferenceInDays,
    FormatPhoneNumberPipe,
    ApplicationStatusPipe,
    ProgramStatusPipe,
    EthnicityPipe,
    drugStatusPipe,
    ControllingCaseStatusPipe,
    SoberStatusPipe,
    CleanStatusPipe,
  ],
})
export class CorePipesModule {}
