import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drugStatus',
})
export class drugStatusPipe implements PipeTransform {
  transform(drug: string): string {
    return {
      opioids: 'Opioids',
      methamphetamine: 'Methamphetamine',
      cocaine: 'Cocaine',
      fentanyl: 'Fentanyl',
    }[drug];
  }
}
 