import { ApplicantSupport, Housing } from './applicant';
import { ApplicationStatus, Gender } from './common';
export interface DraftApplicant {
  id?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  mobile?: string;
  email?: string;
  gender?: Gender;
  status?: ApplicationStatus;
  housing?: Housing;
  createdAt?: Date;
  updatedAt?: Date;
  submittedAt?: Date;
  dateOfBirth?: Date;
  location?: Location;
  locationId?: string;
  questions?: {
    answer?: string;
    category: string;
    questionId: string;
  }[];
  support?: ApplicantSupport;
}
