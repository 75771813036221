export interface Location {
    id: string;
    name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    maxResidents: number;
    totalSpots: number;
  }
  