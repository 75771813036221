export interface UpdateAnswer {
  answer: string;
  category: string;
  questionId: string;
}

export interface Question {
  id: string;
  gender: string;
  question: string;
  order: string;
  category: string;
  createdAt: string;
  updatedAt: string;
}

export interface QuestionWithAnswer {
  id: string;
  answer: string;
  category: string;
  questionId: string;
  question: Question;
  createdAt: string;
  updatedAt: string;
}
