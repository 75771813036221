import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from '@environments/environment';
import { ROUTES } from '@consts';
import { LayoutComponent } from '@shared/layout';
import { AuthGuard } from './auth/helpers';
import { AdminGuard } from './auth/helpers/admin.guard';

const appRoutes: Routes = [
  {
    path: ROUTES.NOT_FOUND,
    loadChildren: () => import('@pages/error/error.module').then((module) => module.ErrorModule),
  },
  {
    path: ROUTES.LOGIN,
    loadChildren: () => import('@pages/login/login.module').then((module) => module.LoginModule),
  },
  {
    path: ROUTES.REGISTER,
    loadChildren: () => import('@pages/register/register.module').then((module) => module.RegisterModule),
  },
  {
    path: ROUTES.RESET_PASSWORD,
    loadChildren: () =>
      import('@pages/reset-password/reset-password.module').then((module) => module.ResetPasswordModule),
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    loadChildren: () =>
      import('@pages/forgot-password/forgot-password.module').then((module) => module.ForgotPasswordModule),
  },
  {
    path: ROUTES.SCREENING_FORM,
    loadChildren: () =>
      import('@pages/screening-form/screening-form.module').then((module) => module.ScreeningFormModule),
  },
  {
    path: ROUTES.PRESCREENING_FORM,
    loadChildren: () =>
      import('@pages/prescreening-form/prescreening-form.module').then((module) => module.PrescreeningFormModule),
  },
  {
    path: 'page',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES.DASHBOARD,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.CALENDAR,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.TASKS,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.SETTINGS,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.APPLICANTS,
        loadChildren: () => import('@pages/applicant/applicants/list.module').then((module) => module.ApplicantsModule),
      },
      {
        path: ROUTES.APPLICANT,
        loadChildren: () =>
          import('@pages/applicant/view/applicant-view.module').then((module) => module.ViewApplicantModule),
      },
      {
        path: ROUTES.EDIT_APPLICANT,
        loadChildren: () =>
          import('@pages/applicant/edit/applicant-edit.module').then((module) => module.ApplicantEditModule),
      },
      {
        path: ROUTES.CLIENTS,
        loadChildren: () => import('@pages/client/clients/list.module').then((module) => module.ApplicantsModule),
      },
      {
        path: ROUTES.CLIENT,
        loadChildren: () =>
          import('@pages/client/view/applicant-view.module').then((module) => module.ViewApplicantModule),
      },
      {
        path: ROUTES.EDIT_CLIENT,
        loadChildren: () =>
          import('@pages/client/edit/client-edit.module').then((module) => module.ApplicantEditModule),
      },
      {
        path: ROUTES.PROFILE,
        loadChildren: () => import('@pages/profile/profile.module').then((module) => module.ProfileModule),
      },
      {
        path: ROUTES.SETTINGS,
        children: [
          {
            path: ROUTES.LOCATIONS,
            loadChildren: () => import('@pages/settings/locations/locations.module').then((module) => module.LocationsModule),
          },
        ],
        canActivate: [AdminGuard]
      },
      {
        path: ROUTES.DRAFT_APPLICANT,
        loadChildren: () =>
          import('@pages/draft-applicant/view/draft-applicant-view.module').then(
            (module) => module.ViewDraftApplicantModule,
          ),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTES.PAGE_DASHBOARD,
  },
  {
    path: '**',
    redirectTo: ROUTES.NOT_FOUND,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.debug.router,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
