import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicationStatus',
})
export class ApplicationStatusPipe implements PipeTransform {
  transform(status: string): string {
    return {
      'prescreening-incomplete': 'Pre-screening incomplete',
      'screening-incomplete': 'Screening incomplete',
      decline: 'Declined',
      approve: 'Approved',
      pending: 'Pending Review',
    }[status];
  }
}
