export enum SocialProvider {
  GOOGLE = 'GOOGLE',
}

export interface Social {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  provider: SocialProvider;
}
