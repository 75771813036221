<ng-container *ngTemplateOutlet="navbar"></ng-container>
<ng-container *ngTemplateOutlet="menu"></ng-container>

<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="header-navbar-shadow" *ngIf="!coreConfig.layout.navbar.hidden"></div>
  <main [@fadeInAnimation]="getRouterOutletState(outlet)" [@zoomIn]="zoomIn(outlet)" [@fadeInLeft]="fadeInLeft(outlet)" [@fadeIn]="fadeIn(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
</div>

<ng-container *ngTemplateOutlet="footer"></ng-container>

<ng-template #navbar>
  <app-navbar
    *ngIf="!coreConfig.layout.navbar.hidden"
    [ngClass]="[
      coreConfig.layout.navbar.customBackgroundColor === true
        ? coreConfig.layout.navbar.background +
          ' ' +
          coreConfig.layout.navbar.type +
          ' ' +
          coreConfig.layout.navbar.backgroundColor
        : coreConfig.layout.navbar.background + ' ' + coreConfig.layout.navbar.type
    ]"
    class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow"
  >
  </app-navbar>
</ng-template>

<ng-template #menu>
  <core-sidebar
    name="menu"
    [collapsed]="coreConfig.layout.menu.collapsed"
    collapsibleSidebar="bs-gt-xl"
    *ngIf="!coreConfig.layout.menu.hidden"
    class="main-menu menu-fixed menu-accordio menu-shadow"
    overlayClass="sidenav-overlay"
    [ngClass]="[
      coreConfig.layout.skin === 'semi-dark' || coreConfig.layout.skin === 'dark' ? 'menu-dark' : 'menu-light',
      coreConfig.layout.menu.collapsed ? '' : 'expanded'
    ]"
  >
    <app-menu menuType="vertical-menu" class="vertical-menu"></app-menu>
  </core-sidebar>
</ng-template>

<ng-template #footer>
  <footer
    *ngIf="!coreConfig.layout.footer.hidden"
    class="footer"
    [ngClass]="
      coreConfig.layout.footer.customBackgroundColor === true
        ? coreConfig.layout.footer.background +
          ' ' +
          coreConfig.layout.footer.type +
          ' ' +
          coreConfig.layout.footer.backgroundColor
        : coreConfig.layout.footer.background + ' ' + coreConfig.layout.footer.type
    "
  ></footer>
</ng-template>
